exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-como-funciona-js": () => import("./../../../src/pages/como-funciona.js" /* webpackChunkName: "component---src-pages-como-funciona-js" */),
  "component---src-pages-funcionalidades-acompanhamento-js": () => import("./../../../src/pages/funcionalidades/acompanhamento.js" /* webpackChunkName: "component---src-pages-funcionalidades-acompanhamento-js" */),
  "component---src-pages-funcionalidades-apps-js": () => import("./../../../src/pages/funcionalidades/apps.js" /* webpackChunkName: "component---src-pages-funcionalidades-apps-js" */),
  "component---src-pages-funcionalidades-exercicios-js": () => import("./../../../src/pages/funcionalidades/exercicios.js" /* webpackChunkName: "component---src-pages-funcionalidades-exercicios-js" */),
  "component---src-pages-funcionalidades-pagina-profissional-js": () => import("./../../../src/pages/funcionalidades/pagina-profissional.js" /* webpackChunkName: "component---src-pages-funcionalidades-pagina-profissional-js" */),
  "component---src-pages-hey-js": () => import("./../../../src/pages/hey.js" /* webpackChunkName: "component---src-pages-hey-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-obrigado-js": () => import("./../../../src/pages/obrigado.js" /* webpackChunkName: "component---src-pages-obrigado-js" */),
  "component---src-pages-politica-de-privacidade-js": () => import("./../../../src/pages/politica-de-privacidade.js" /* webpackChunkName: "component---src-pages-politica-de-privacidade-js" */),
  "component---src-pages-precos-js": () => import("./../../../src/pages/precos.js" /* webpackChunkName: "component---src-pages-precos-js" */),
  "component---src-pages-termos-de-servico-js": () => import("./../../../src/pages/termos-de-servico.js" /* webpackChunkName: "component---src-pages-termos-de-servico-js" */)
}

